type PropertyType = 'og:description' | 'og:image' | 'og:title' | 'og:type' | 'og:url'

export function setMetadata(property: PropertyType, content: string): void {
  const metaProperty = document.head.querySelector(`meta[property="${property}"]`)
  if (metaProperty === null) {
    const newMetaElement = document.createElement('meta')
    newMetaElement.setAttribute('property', property)
    newMetaElement.setAttribute('content', content)
    document.head.appendChild(newMetaElement)
  } else {
    metaProperty.setAttribute('content', content)
  }
}
